<template>
  <div class="page-wrap">
    <img
      class="top-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/top.png"
    />
    <img
      class="benefits-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/benefits.png"
    />
    <van-field
      class="input-tel"
      v-model="form.phone_number"
      type="tel"
      maxlength="11"
      placeholder="请输入手机号码"
    />
    <van-field
      class="input-code"
      v-model="form.phone_code"
      type="number"
      center
      maxlength="6"
      autocomplete="one-time-code"
      placeholder="请输入验证码"
    >
      <template slot="right-icon">
        <div class="code" v-show="sendAuthCode" @click="getAuthCode">
          获取验证码
        </div>
        <div class="code disabled-code" v-show="!sendAuthCode">
          <span>{{ auth_time }}s</span>
        </div>
      </template>
    </van-field>
    <img
      class="submit-button"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/submit.png"
      @click="onSubmit"
    />
    <div class="protocol-wrap" @click="checked = !checked">
      <img
        class="protocol-check"
        :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/${
          checked ? 'checked' : 'unchecked'
        }.png`"
      />
      <span>我已详细阅读并同意</span>
      <span class="protocol-text" @click.stop="visibility = true"
        >《服务协议》</span
      >
    </div>
    <img
      class="rule-icon"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/rule_icon.png"
    />
    <div class="rule-content">{{ ruleContent }}</div>

    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <div class="dialog-title">服务协议</div>
        <div class="dialog-text">{{ dialogContent }}</div>
        <img
          class="confirm-button"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/confirm.png"
          @click="visibility = false"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { codeAPI, alipaySmsPayAPI } from "@/api/trialSelectionMember";
import { ruleContent, dialogContent } from "./index.js";
export default {
  data() {
    return {
      form: {},
      checked: false,
      visibility: false,
      sendAuthCode: true,
      auth_time: 0,
      ruleContent,
      dialogContent,
    };
  },

  methods: {
    // 验证码
    async getAuthCode() {
      const { phone_number } = this.form;
      if (!phone_number) return this.$toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(phone_number))
        return this.$toast("手机号码有误请重新输入");
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await codeAPI({ phone_number });
      this.$toast.clear();
      if (res.code != 0) {
        this.$toast.fail(res.message);
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    onSubmit() {
      const { phone_number, phone_code } = this.form;
      if (!phone_number) return this.$toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(phone_number))
        return this.$toast("手机号码有误请重新输入");
      if (!phone_code) return this.$toast("请输入验证码");
      if (!this.checked)
        return this.$toast("请勾选“我已详细阅读并同意《服务协议》”");
      alipaySmsPayAPI({
        phone_number,
        phone_code,
        item_name: "视听甄选会员",
      }).then((res) => {
        if (res.code != 0) {
          this.$toast.fail(res.message);
        } else {
            location.href = res.sign_str
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.page-wrap {
  position: relative;
  background: #ff826a;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-img {
    width: 100vw;
  }
  .benefits-img {
    width: 702px;
    margin: auto;
    display: block;
  }

  ::v-deep .van-cell {
    width: 654px;
    height: 90px;
    background-color: #ffffff;
    border-radius: 50px;
    margin: 0px auto 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 0 0 40px;
    &.input-tel {
      margin: 54px 0 24px;
    }
    &.input-code {
      .code {
        width: 190px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #e42970;
        font-size: 28px;
        font-weight: normal;
        background-color: #ffdbd2;
      }
      .disabled-code {
        color: #999999;
        background-color: #e6e6e6;
      }
    }

    .van-field__body {
      height: 100%;

      input {
        height: 100%;
      }
    }

    &::after {
      border: none;
    }

    .van-field__control {
      color: #000;
      font-weight: normal;
      &::-webkit-input-placeholder {
        font-size: 34px;
        font-weight: 40;
      }
    }
  }
  .submit-button {
    width: 654px;
    height: 90px;
    margin: 36px 0 24px;
  }
  .protocol-wrap {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 60px;
    .protocol-check {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
    }
    .protocol-text {
      color: #feed72;
    }
  }
  .rule-icon {
    width: 600px;
    height: 73px;
  }
  .rule-content {
    width: 700px;
    padding: 20px 23px;
    margin-bottom: 55px;
    background: linear-gradient(132deg, #ffffff 0%, #ffdfd9 100%);
    border-radius: 24px;
    white-space: pre-line;
    font-size: 24px;
    color: #333333;
    line-height: 36px;
  }
  .dialog-wrap {
    width: 630px;
    height: 70vh;
    background: linear-gradient(151deg, #ffffff 0%, #ffdfd9 100%);
    border-radius: 24px;
    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 37px;
      height: 100%;
      .dialog-title {
        font-weight: 500;
        font-size: 34px;
        color: #000000;
        margin-bottom: 30px;
        flex: none;
      }
      .dialog-text {
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        white-space: pre-line;
        overflow: auto;
        flex: 1;
      }
      .confirm-button {
        width: 510px;
        height: 90px;
        margin-top: 40px;
        flex: none;
      }
    }
  }
}
</style>
